import React from 'react'
import CompanyDatabase from '../components/CompanyDatabase/CompanyDatabase'
import Layout from '../components/Layout/Layout'

export default function CompanyDatabasePage() {
    return (
        <Layout>
            <CompanyDatabase />
        </Layout>
    )
}
