import React from 'react'
import LandingView from '../LandingView/LandingView'
import Table from '../Table/Table'

export default function Home() {
    return (
        <>
            <LandingView />
            <Table />
        </>
    )
}
