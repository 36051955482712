import classes from './Navbar.module.css'

export function Navbar() {
    // const [active, setActive] = useState(false)
    // const navigate = useNavigate()
    // const { stateAuth, dispatchAuth } = useContext(Auth)
    // const { stateUser, dispatchUser } = useContext(UserInfo)
    // const userInfo = stateUser.info

    // const handleLogout = async (e) => {
    //     e.preventDefault()
    //     const logout = await axios.get(`${api}/login/logout`, {
    //         headers: {
    //             'Access-Control-Allow-Origin': frontEnd,
    //         },
    //         withCredentials: true,
    //     })
    //     if (logout) {
    //         dispatchAuth({ type: 'remove' })
    //         dispatchUser({ type: 'remove' })
    //     } else {
    //         alert('unable to logout')
    //     }
    // }
    return (
        <>
            <div className={classes.nav}>
                <p></p>
            </div>
        </>
    )
}
