import React from 'react'
import Marquee from 'react-fast-marquee'
import classes from './LandingView.module.css'

export default function LandingView() {
    return (
        <div className={classes.landingView}>
            <div>
                <p>Company Database</p>
                <p>
                    by <span style={{ color: 'var(--home-color)', fontWeight: 'bold' }}>BIZDATA</span>
                    <span style={{ color: 'var(--home-light)', fontWeight: 'bold' }}>INSIGHTS</span>
                </p>
            </div>
            <div>
                <p>
                    Get access to the information of{' '}
                    <span style={{ color: 'var(--home-color)', fontWeight: 'bold' }}>10,000+</span> companies of
                    Bangladesh
                </p>

                <div>
                    <Marquee>
                        <div className={classes.imageWrapper}>
                            <p>Pran</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Cocacola</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Marico</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Brac Bank</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Grameen Phone</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>BAT</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Robi</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Walton</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Bkash</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Pran</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Cocacola</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Marico</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Brac Bank</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Grameen Phone</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>BAT</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Robi</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Walton</p>
                        </div>
                        <div className={classes.imageWrapper}>
                            <p>Bkash</p>
                        </div>
                    </Marquee>
                </div>
            </div>
        </div>
    )
}
