// import { useReducer } from 'react'
import { Routes, Route } from 'react-router-dom'
// import { Auth, UserInfo } from './contexts/allContext'
// import { authReducer, authState } from './contexts/reducer/authReducer'
// import { userReducer, userState } from './contexts/reducer/userReducer'
// import { Route, Routes } from 'react-router'
import CompanyDatabasePage from './pages/CompanyDatabasePage'
import HomePage from './pages/HomePage'

// import ProtectedRoute from './routes/ProtectedRoute'

function App() {
    // const [stateAuth, dispatchAuth] = useReducer(authReducer, authState)
    // const [stateUser, dispatchUser] = useReducer(userReducer, userState)

    return (
        // <Auth.Provider value={{ stateAuth, dispatchAuth }}>
        //     <UserInfo.Provider value={{ stateUser, dispatchUser }}>
        //         <Routes>
        //             <Route path="" element={<HomePage />} />

        //             <Route path="/*" element={<ProtectedRoute />}>
        //                 <Route path="/CompanyDatabase/*" element={<CompanyDatabasePage />} />
        //             </Route>
        //         </Routes>
        //     </UserInfo.Provider>
        // </Auth.Provider>
        <Routes>
            <Route path="" element={<HomePage />} />
            <Route path="/CompanyDatabase/*" element={<CompanyDatabasePage />} />
        </Routes>
    )
}

export default App
