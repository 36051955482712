import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import classes from './CompanyDatabase.module.css'

export default function CompanyDatabase() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className={classes.companyDatabase}>
            <div>
                <button onClick={() => navigate('/')}>Back to search</button>
                <p>{location.state.name}</p>
            </div>
        </div>
    )
}
