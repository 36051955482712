import React from 'react'
// import { useNavigate } from "react-router";
import classes from './Layout.module.css'
import { Navbar } from './Navbar/Navbar'

// const api = process.env.REACT_APP_BASE_URL;
// const frontEnd = process.env.REACT_APP_CLIENT_URL;

export default function Layout({ children }) {
    //   const navigate = useNavigate();

    return (
        <>
            <div className={classes.layout}>
                <Navbar />
                <div className={classes.underNavbar}>{children}</div>
            </div>
        </>
    )
}
